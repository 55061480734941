import {links, socials} from './data'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <div className="container footer__container">
        <ul className='nav__menu'>
          {
            links.map(fLink => <li key={fLink.id}><a href={fLink.link}>{fLink.title}</a></li>)
          }
        </ul>
        <div className="footer__socials">
          {
            socials.map(social => <a key={social.id} href={social.link} target="_blank" rel="noopener noreferrer">{social.icon}</a>)
          }
        </div>
      </div>
      <div className="footer__copyright">
        <small>2025 IntElligence Tech Solutions &copy; All Rights Reserved </small>
      </div>
      
      <div className='privacy-policy'>
      <a className="privacy_policy" href='https://drive.google.com/file/d/1URX3jQQl4ccm_FC_U9D6_OxQpKXcJRXm/view?usp=drive_link' target='_blank' >Privacy and Policy</a>
      </div>

      <div className='terms'>
      <a className="terms" href='https://drive.google.com/file/d/1KurKGH81V_gu2o8l4Y73M-8K9_zOmJPG/view?usp=sharing' target='_blank' >Terms & Conditions</a>
      </div>


    </footer>
  )
}

export default Footer